document.addEventListener('DOMContentLoaded', function () {
  const menu = document.querySelector('.menu');
  const menuLeft = document.querySelector('.menu__left');
  const menuRight = document.querySelector('.menu__right');
  const menuLogo = document.querySelector('.menu__left > img');
  const menuList = document.querySelector('.menu__list');
  const menuExtras = document.querySelector('.menu__extras');
  const btnOpen = document.querySelector('.header__burger');
  const btnClose = document.querySelector('.menu__close');
  const body = document.querySelector('body');
  const main = document.querySelector('main');
  const footer = document.querySelector('footer');
  const mainAndFooter = [main, footer];

  const btnSousMenu = menu
    ? menu.querySelector('.menu-item-has-children > a')
    : null;
  const sousMenu = menu ? menu.querySelector('.menu-item-has-children') : null;

  if (btnSousMenu && sousMenu) {
    btnSousMenu.addEventListener('click', function (e) {
      e.preventDefault();

      // Toggle la classe 'open'
      if (sousMenu.classList.contains('open')) {
        sousMenu.classList.remove('open');
      } else {
        sousMenu.classList.add('open');
      }
    });
  }

  if (btnOpen) {
    btnOpen.addEventListener('click', function () {
      const tl = gsap.timeline({ defaults: { ease: 'ease-out' } });

      gsap.set(menuLeft, { x: '-100%' });
      gsap.set(menuRight, { x: '100%' });
      gsap.set(menuLogo, { autoAlpha: 0 });
      gsap.set(menuList, { autoAlpha: 0, x: -100 });
      gsap.set(menuExtras, { autoAlpha: 0, y: 100 });
      gsap.set(menu, { display: 'grid' });

      tl.to(menuLeft, {
        x: 0,
        duration: 0.5,
        onStart: () => {
          if (window.innerWidth < 992) {
            document.documentElement.classList.add('no-scroll');
          } else {
            document.body.classList.add('no-scroll');
          }
        },
      });
      if (window.innerWidth < 576) {
        tl.to(mainAndFooter, { x: '-75%', duration: 0.5 }, '<');
      }
      tl.to(menuRight, { x: 0, duration: 0.5 }, '<');
      tl.to(menuLogo, { autoAlpha: 1, duration: 0.5 })
        .to(menuList, { autoAlpha: 1, x: 0, duration: 0.5 }, '<')
        .to(
          menuExtras,
          {
            autoAlpha: 1,
            y: 0,
            duration: 0.5,
            onComplete: () => {
              menu.style.overflowY = 'auto';
            },
          },
          '<'
        );
    });
  }

  if (btnClose) {
    btnClose.addEventListener('click', closeMenu);
  }

  mainAndFooter.forEach((element) => {
    element.addEventListener('click', function () {
      if (document.documentElement.classList.contains('no-scroll')) {
        closeMenu();
      }
    });
  });

  function closeMenu() {
    const tl = gsap.timeline();
    tl.to(menuLogo, { autoAlpha: 0, duration: 0.5 })
      .to(menuList, { autoAlpha: 0, x: -100, duration: 0.5 }, '<')
      .to(menuExtras, { autoAlpha: 0, y: 100, duration: 0.5 }, '<')
      .to(menuLeft, { x: '-100%', duration: 0.5 });
    if (window.innerWidth < 576) {
      tl.to(mainAndFooter, { x: '0', duration: 0.5 }, '<');
    }
    tl.to(
      menuRight,
      {
        x: '100%',
        duration: 0.5,
        onComplete: () => {
          menu.style.display = 'none';
          // Remove 'no-scroll' class from body to allow scrolling
          if (window.innerWidth < 992) {
            document.documentElement.classList.remove('no-scroll');
          } else {
            document.body.classList.remove('no-scroll');
          }
        },
      },
      '<'
    );
  }
});
